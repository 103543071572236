import request from '@/utils/request'

// 分页查询安能运单号列表
export function findAllMailNoByPage(data) {
  return request({
    method: 'post',
    url: `/auth/ane56preInfo/findAllMailNoByPage`,
    data
  })
}

// 根据安能运单号查询乡镇信息
export function findTownByMailNo(data) {
  return request({
    method: 'post',
    url: `/auth/ane56preInfo/findTownByMailNo`,
    data
  })
}

